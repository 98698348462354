import _ from "lodash";

const guidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-4[0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;

function generateAvatarInitials(fullName) {
    // Split the full name into individual names
    const names = fullName.split(" ").filter(name => name !== "");
    let initials = "";

    // Check the number of names and generate initials accordingly
    if (names.length === 1) {
        initials = names[0][0];
    } else if (names.length === 2) {
        initials = names[0][0] + names[1][0];
    } else if (names.length === 3) {
        initials = names[0][0] + names[1][0];
    } else if (names.length >= 4) {
        initials = names[0][0] + names[2][0];
    }

    return initials.toUpperCase();
}

function isValidString(string) {
    return !_.isEmpty(string) && !_.isNil(string);
}

function isValidGuid(guid) {
    return isValidString(guid) && guidRegex.test(guid);
}

function isValidEnum(enums, _enum, identifier = 'id') {
    return enums.some((e) => e[identifier] === _enum);
}

export {
    generateAvatarInitials,
    isValidString,
    isValidGuid,
    isValidEnum,
};