<template>
  <div>
    <div class="flex flex-row items-center py-4 justify-between">
      <el-upload class="avatar-uploader" action="" :show-file-list="false" :on-change="handleAvatarChange"
        :before-upload="beforeAvatarUpload" :auto-upload="false" :limit="1">
        <img v-if="model.logo.value" :src="model.logo.src" class="avatar">
        <i v-else class="el-icon-plus avatar-uploader-icon p-8"></i>
      </el-upload>
      <h1 class="text-lg font-bold inline-flex items-center">
        <strong>&nbsp;Crear Sucursal</strong>
      </h1>
    </div>
    <div class="flex flex-col space-y-6">
      <el-form :model="model" label-position="right" label-width="130px">
        <el-row>
          <el-col :span="12" class="text-right">
            <el-form-item label="Nombre">
              <el-input v-model="model.name"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="12" class="text-right">
            <el-form-item label="Teléfono">
              <sa-phone-country :model="model" />
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="12" class="text-right">
            <el-form-item label="Zona Horaria">
              <el-select class="w-full" filterable v-model="model.timezone">
                <el-option v-for="timezone in timezones" :key="timezone.id" :label="timezone.displayName"
                  :value="timezone.id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="12" class="text-right">
            <el-form-item label="Formato de Fecha">
              <el-select class="w-full" v-model="model.dateFormat">
                <el-option v-for="dateFormat in DateFormats" :key="dateFormat" :label="dateFormat.toLowerCase()"
                  :value="dateFormat"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="12" class="text-right">
            <el-form-item label="País">
              <el-select class="w-full" clearable v-model="model.country">
                <el-option class="h-full" :value="undefined" disabled>
                  <div class="flex flex-col py-1 text-center">
                    <span class="text-gray-500">---Paises Americanos---</span>
                  </div>
                </el-option>
                <el-option v-for="country in AmericanCountries" :key="country" :value="country"
                  :label="country"></el-option>
                <el-option class="h-full" :value="undefined" disabled>
                  <div class="flex flex-col py-1 text-center">
                    <span class="text-gray-500">---Paises Europeos---</span>
                  </div>
                </el-option>
                <el-option class="h-full" v-for="country in EuropeanCountries" :key="country" :value="country"
                  :label="country"></el-option>
                <el-option class="h-full" :value="undefined" disabled>
                  <div class="flex flex-col py-1 text-center">
                    <span class="text-gray-500">---Otros---</span>
                  </div>
                </el-option>
                <el-option class="h-full" value="otro" label="Otro"></el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="12" class="text-right">
            <el-form-item label="Tipo de Moneda">
              <el-select class="w-full" v-model="model.currency">
                <el-option value="USD" label="USA" />
                <el-option value="MXN" label="MÉXICO" />
                <el-option value="EUR" label="EURO" />
                <el-option value="ARS" label="ARGENTINA" />
                <el-option label="COLOMBIA" value="COP" />
                <el-option label="ECUADOR" value="ECS" />
                <el-option label="GUATEMALA" value="GTQ" />
                <el-option label="VENEZUELA" value="BS" />
                <el-option label="PERU" value="PEN" />
                <el-option label="CHILE" value="CLP" />
                <el-option label="REPUBLICA DOMINICANA" value="DOP" />
                <el-option label="HONDURAS" value="HNL" />
                <el-option label="URUGUAY" value="UYU" />
                <el-option label="NICARAGUA" value="NIO" />
                <el-option label="PARAGUAY" value="PYG" />
                <el-option label="BOLIVIA" value="BOB" />
                <el-option label="EL SALVADOR" value="SVC" />
                <el-option label="CUBA" value="CUP" />
                <el-option label="COSTA RICA" value="CRC" />
                <el-option label="BRASIL" value="BRL" />
                <el-option label="PANAMA" value="PAB" />
                <el-option label="BELICE" value="BZD" />
                <el-option label="CANADA" value="CAD" />
                <el-option label="SUIZA" value="CHF" />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="24" class="text-right">
            <el-form-item label="Dirección">
              <el-input type="textarea" :rows="3" v-model="model.address" />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>

      <div class="ml-auto">
        <sa-button type="primary" class="items-center font-bold rounded" @click="save"
          v-loading.fullscreen.lock="loading">
          Guardar
        </sa-button>
      </div>
    </div>
  </div>
</template>
<style scoped>
.avatar-uploader {
  display: inline-block;
  width: 100px;
  height: 100px;
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  text-align: center;
  align-content: center;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.avatar {
  width: 100%;
  height: 100%;
  display: block;
}
</style>
<script>
import _ from "lodash";
import CatalogService from "@/services/CatalogService";
import FileService from "@/services/FileService";
import {
  FileType,
  DateFormats,
  AmericanCountries,
  EuropeanCountries,
} from "@/constants";
import {
  isValidString,
  isValidGuid,
  isValidEnum,
} from "@/utils/strings";

export default {
  name: 'NewOffice',
  props: {
    loading: {
      type: Boolean,
      default: () => false,
    },
    visible: {
      type: Boolean,
      default: () => false,
    },
  },
  mounted() {
    this.loadTimezones();
  },
  data() {
    return {
      timezones: [],
      DateFormats,
      AmericanCountries,
      EuropeanCountries,
      model: {
        name: '',
        country: '',
        phoneCountryCodeId: null,
        phone: '',
        currency: '',
        timezone: '',
        dateFormat: '',
        address: '',
        logo: {
          src: '',
          value: null,
        },
      },
    }
  },
  watch: {
    'visible': {
      deep: true,
      handler: _.debounce(async function () {
        this.model = {
          name: '',
          country: '',
          phoneCountryCodeId: null,
          phone: '',
          currency: '',
          timezone: '',
          dateFormat: '',
          address: '',
          logo: {
            src: '',
            value: null,
          },
        };
      }, 300)
    },
  },
  methods: {
    async loadTimezones() {
      this.timezones = await CatalogService.getTimeZones();
    },
    handleAvatarChange(file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        this.model.logo.src = e.target.result;
        this.model.logo.value = file.raw;
      };
      reader.readAsDataURL(file.raw);
      return false; // Prevents automatic upload
    },
    beforeAvatarUpload(file) {
      const isImage = file.type.startsWith('image/');
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isImage) {
        this.$message.error('El archivo subido debe ser una imagen');
      }
      if (!isLt2M) {
        this.$message.error('La imagen debe ser menor a 2MB');
      }
      return isImage && isLt2M;
    },
    async save() {
      let error = false;

      // VALIDATIONS
      if (!isValidString(this.model.name)) {
        this.$toastr.e("Falla en formulario, campo (NOMBRE)");
        error = true;
      }

      if (!isValidGuid(this.model.phoneCountryCodeId)) {
        this.$toastr.e("Falla en formulario, campo (LADA)");
        error = true;
      }

      if (!isValidString(this.model.phone)) {
        this.$toastr.e("Falla en formulario, campo (TELEFONO)");
        error = true;
      }

      if (!isValidEnum(this.timezones, this.model.timezone)) {
        this.$toastr.e("Falla en formulario, campo (ZONA HORARIA)");
        error = true;
      }

      if (![DateFormats.American, DateFormats.European].includes(this.model.dateFormat)) {
        this.$toastr.e("Falla en formulario, campo (FORMATO DE FECHA)");
        error = true;
      }

      if (!isValidString(this.model.currency)) {
        this.$toastr.e("Falla en formulario, campo (TIPO DE MONEDA)");
        error = true;
      }

      let logo;
      // EVALUATE, UPLOAD AND ADD LOGO TO BRANCH OFFICE
      if (!_.isEmpty(this.model.logo.value) && !_.isNil(this.model.logo.value)) {
        const formData = new FormData();
        formData.append('File', this.model.logo.value);
        formData.append('Type', FileType.BusinessInfo);
        logo = await FileService.create(formData, { 'Content-Type': 'multipart/form-data' });
      }

      if (error) {
        if (logo) {
          await FileService.delete({ files: [logo] });
        }
        return;
      }

      this.onSaveBranchOffice({
        name: this.model.name,
        country: this.model.country,
        phoneCountryCodeId: this.model.phoneCountryCodeId,
        phone: this.model.phone,
        currency: this.model.currency,
        timezone: this.model.timezone,
        dateFormat: this.model.dateFormat,
        address: this.model.address,
        logo: logo,
      });
    },
    onSaveBranchOffice(branchOffice) {
      this.$emit('onSaveBranchOffice', branchOffice);
    }
  },
}
</script>