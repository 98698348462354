<template>
  <administration-layout>
    <div class="pt-24">
      <div class="sticky top-24 bg-white z-10">
        <div class="w-full p-2 text-right">
          <div>
            <sa-button
              :disabled="
                !isProMembership ||
                (isProMembership &&
                  !isProMaxMembership &&
                  $store.state.business.offices.length >= 3)
              "
              @click="createForm.visible = true"
            >
              + Agregar Sucursal
            </sa-button>
          </div>
        </div>
        <OnlyProInfo
          v-if="!isProMembership"
          text="El
    panel de Sucursales no está incluido en la
    membresía que tienes actualmente, Si estás
    interesado en tener más de una sucursal puedes ir a"
        ></OnlyProInfo>
        <div class="w-full border mb-4 p-4">
          <div class="w-full mb-2">
            <strong>
              Lista de usuarios, arrastra y suelta los usuarios en las
              sucursales a los que las desees asignar
            </strong>
          </div>
          <div
            v-for="employee in employees"
            :key="employee.id"
            class="avatar"
            draggable="true"
            :title="employee.fullname"
            @dragstart="dragEmployeeStart(employee, $event)"
            @dragover.prevent
            @dragenter="dragEnter"
            @dragleave="dragLeave"
            @drop="drop(employee, $event)"
          >
            <span class="avatar-initials">{{
              getEmployeeAvatar(employee)
            }}</span>
          </div>
        </div>
      </div>
      <div class="w-full pt-8 px-4 flex flex-row overflow-x-auto">
        <div
          class="office"
          v-for="office in $store.state.business.offices"
          :key="office.id"
          @dragover.prevent="dragOverOffice"
          @dragenter="dragEnterOffice(office)"
          @dragleave="dragLeaveOffice"
          @drop="dropOffice(office, $event)"
        >
          <div class="office-name">
            <span>{{ office.name }}</span>
          </div>
          <div
            class="office-actions bg-white hover:bg-purple-500 hover:text-white"
            @click="openUpdateForm(office.id)"
          >
            <span><i class="bx bx-edit"></i></span>
          </div>
          <div class="employees">
            <div
              v-for="employee in office.employees"
              :key="employee.id"
              class="employee"
            >
              {{ employee.fullname }}
              <div
                class="cursor-pointer hover:text-red-500"
                @click="unassign(employee.id, office)"
              >
                <i class="bx bx-x"></i>
              </div>
            </div>
          </div>
        </div>
      </div>

      <el-dialog append-to-body show-close :visible.sync="createForm.visible">
        <new-office
          :visible="createForm.visible"
          :loading="createForm.loading"
          @onSaveBranchOffice="createForm.save"
        ></new-office>
      </el-dialog>

      <el-dialog append-to-body show-close :visible.sync="updateForm.visible">
        <office
          :model="updateForm.model"
          :loading="updateForm.loading"
          @onSaveBranchOffice="updateForm.save"
        ></office>
      </el-dialog>
    </div>
  </administration-layout>
</template>
<style scoped>
.avatar {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  margin-bottom: 10px;
  color: #ffffff;
  font-weight: bold;
  background-color: #cdcdcd;
  cursor: pointer;
  user-select: none;
}

.avatar-initials {
  font-size: 20px;
}

.office {
  border: 2px dashed #ccc;
  border-radius: 10px;
  padding: 20px;
  position: relative;
  /* Hacer que el nombre de la sucursal se pueda posicionar con respecto al elemento */
  min-width: 200px;
  /* Ancho mínimo de la sucursal */
  min-height: 50vh;
  /* Altura mínima de la sucursal */
  margin-right: 24px;
  margin-bottom: 32px;
  background-color: #ffffff;
  width: 250px;
}

.office-actions {
  position: absolute;
  top: -20px;
  right: 5px;
  padding: 5px 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 10px;
  cursor: pointer;
}

.office-name {
  position: absolute;
  top: -20px;
  right: 50px;
  background-color: white;
  padding: 5px 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 10px;
  user-select: none;
  white-space: nowrap;
  width: 11.5rem;
  text-overflow: ellipsis;
  overflow: hidden;
}

.employees {
  margin-top: 20px;
}

.employee {
  margin-bottom: 10px;
  user-select: none;
  display: inline-flex;
  justify-content: space-between;
  width: 100%;
  border-bottom: 2px solid whitesmoke;
}
</style>
<script>
import _ from "lodash";
import error from "@/mixins/error";
import { AccountTypeEnum } from "@/constants";
import { generateAvatarInitials } from "@/utils/strings";
import NewOffice from "./components/NewOffice";
import Office from "./components/Office";
import BusinessService from "@/services/BusinessService";
import { sanityCheck } from "@/utils/logos";

export default {
  name: "Offices",
  mixins: [error],
  props: {
    action: {
      type: String,
      default: () => null,
    },
  },
  components: {
    AdministrationLayout: () =>
      import("@/components/organisms/TheAdministrationApp"),
    OnlyProInfo: () => import("@/components/atoms/SaOnlyProInfo.vue"),
    NewOffice,
    Office,
  },
  mounted() {
    if (!_.isNil(this.$route.params.action)) {
      switch (this.$route.params.action) {
        case "open-create-form":
          this.openCreateForm();
          break;
        default:
      }
    }
  },
  data() {
    return {
      isProMembership:
        this.$store.state.account.membershipType === 2 ||
        this.$store.state.account.membershipType === 3,
      isProMaxMembership: this.$store.state.account.membershipType === 3,
      dragEmployee: null,
      dragOverOfficeId: null,
      createForm: {
        visible: false,
        loading: false,
        save: this.createBranchOffice,
      },
      updateForm: {
        visible: false,
        loading: false,
        save: this.updateBranchOffice,
        model: {
          id: null,
          name: "",
          country: "",
          phoneCountryCodeId: null,
          phone: "",
          currency: "",
          timezone: "",
          dateFormat: "",
          address: "",
          logo: {
            src: "",
            value: null,
          },
        },
      },
    };
  },
  computed: {
    employees() {
      return this.$store.state.business.employees.filter(
        (employee) => employee.accountType !== AccountTypeEnum.Owner
      );
    },
  },
  methods: {
    getEmployeeAvatar(employee) {
      return generateAvatarInitials(employee.fullname);
    },
    getFormData(branchOffice) {
      const formData = new FormData();

      if (branchOffice.id) {
        formData.append("Id", branchOffice.id);
      }

      if (branchOffice.logo) {
        formData.append("Logo", sanityCheck("businessinfo", branchOffice.logo));
      }

      formData.append("Name", branchOffice.name);
      formData.append("Country", branchOffice.country);
      formData.append("Phone", branchOffice.phone);
      formData.append("PhoneCountryCodeId", branchOffice.phoneCountryCodeId);
      formData.append("Address", branchOffice.address);
      formData.append("Currency", branchOffice.currency);
      formData.append("Timezone", branchOffice.timezone);
      formData.append("DateFormat", branchOffice.dateFormat);

      return formData;
    },
    openCreateForm() {
      this.createForm.visible = true;
    },
    async createBranchOffice(branchOffice) {
      this.createForm.loading = true;
      try {
        const formData = this.getFormData(branchOffice);
        await BusinessService.create(formData);
        this.$toastr.s("Se creo con exito");
        this.createForm.visible = false;
      } catch (error) {
        const errorMessage = this.getErrorMessage(error);
        this.$toastr.e(errorMessage);
      } finally {
        this.createForm.loading = false;
      }
    },
    async updateBranchOffice(branchOffice) {
      this.updateForm.loading = true;
      try {
        const formData = this.getFormData(branchOffice);
        await BusinessService.update(formData);
        this.$toastr.s("Se actualizo con exito");
        this.updateForm.visible = false;
        this.updateForm.model = {
          id: null,
          name: "",
          country: "",
          phoneCountryCodeId: null,
          phone: "",
          currency: "",
          timezone: "",
          dateFormat: "",
          address: "",
          logo: {
            src: "",
            value: null,
          },
        };
      } catch (error) {
        const errorMessage = this.getErrorMessage(error);
        this.$toastr.e(errorMessage);
      } finally {
        this.updateForm.loading = false;
      }
    },
    openUpdateForm(officeId) {
      const office = this.$store.state.business.offices.find(
        ({ id }) => id === officeId
      );
      this.updateForm.visible = true;
      this.updateForm.model = {
        id: office.id,
        name: office.name,
        country: office.country,
        phoneCountryCodeId: office.phoneCountryId,
        phone: office.phone,
        currency: office.currency,
        timezone: office.timezone,
        dateFormat: office.dateFormat,
        address: office.address,
        logo: {
          src: `${process.env.VUE_APP_S3_BUCKET}/${office.logo}`,
          value: office.logo,
        },
      };
    },
    async unassign(accountId, office) {
      try {
        await BusinessService.unassign({
          accountId,
          businessInfoId: office.id,
        });
        office.employees = office.employees.filter(
          (employee) => employee.id !== accountId
        );
        this.$toastr.s("Se desasigno con exito");
      } catch (error) {
        const errorMessage = this.getErrorMessage(error);
        this.$toastr.e(errorMessage);
      }
    },

    dragEmployeeStart(employee, event) {
      // Almacena el empleado que está siendo arrastrado
      this.dragEmployee = employee;
      // Necesario para que el arrastre funcione en algunos navegadores
      event.dataTransfer.setData("employeeId", employee.id);
    },
    dragEnter(event) {
      // Agrega una clase de estilo al elemento cuando un elemento es arrastrado sobre él
      event.target.classList.add("drag-over");
    },
    dragLeave(event) {
      // Remueve la clase de estilo cuando el elemento es arrastrado fuera de él
      event.target.classList.remove("drag-over");
    },
    drop(employee, event) {
      // Previene el comportamiento predeterminado de la caída
      event.preventDefault();

      // Obtiene el ID del empleado que está siendo arrastrado desde los datos de la transferencia
      const employeeId = event.dataTransfer.getData("employeeId");

      // Aquí puedes manejar la lógica de la caída del empleado, por ejemplo, cambiar su posición en la lista
      console.log("Empleado ID:", employeeId, "soltado en", employee.name);

      // Remueve la clase de estilo al soltar el elemento
      event.target.classList.remove("drag-over");
    },

    dragOverOffice() {
      // No necesitamos hacer nada en este método para las sucursales
    },
    dragEnterOffice(office) {
      this.dragOverOfficeId = office.id;
    },
    dragLeaveOffice() {
      this.dragOverOfficeId = null;
    },
    async dropOffice(office, event) {
      event.preventDefault();
      if (office.employees.map(({ id }) => id).includes(this.dragEmployee.id)) {
        this.$toastr.i(
          `El usuario (${this.dragEmployee.fullname}) ya se encuentra asignado a sucursal (${office.name})`
        );
        return;
      }

      try {
        await BusinessService.assign({
          accountId: this.dragEmployee.id,
          businessInfoId: office.id,
        });
        office.employees.push(this.dragEmployee);
        this.$toastr.s("Se asigno con exito");

        this.dragEmployee = null; // Reiniciar el empleado arrastrado
        this.dragOverOfficeId = null; // Reiniciar la sucursal arrastrada
      } catch (error) {
        const errorMessage = this.getErrorMessage(error);
        this.$toastr.e(errorMessage);
      }
    },
  },
};
</script>
